import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-old-dialogues",
      "style": {
        "position": "relative"
      }
    }}>{`New Old Dialogues`}<a parentName="h1" {...{
        "href": "#new-old-dialogues",
        "aria-label": "new old dialogues permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel emphasises honest, open, and `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation"
      }}>{`sincere conversation`}</a>{`
between peers. It is only through ongoing, care-full dialogue with a great diversity of people
that we can `}<a parentName="p" {...{
        "href": "https://youtu.be/5H164LqEwiA?t=3623"
      }}>{`nurture the kind of ecosystem`}</a>{` which might be described as a
"`}<a parentName="p" {...{
        "href": "/learn/module-3"
      }}>{`better web`}</a>{`". Whatever your own goals, know that there is nothing
more `}<a parentName="p" {...{
        "href": "/learn/module-1/value"
      }}>{`valuable`}</a>{` in this world than True Voice.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The gunfire around us makes it hard to hear. But the human voice is different from other
sounds. It can be heard over noises that bury everything else. Even when it's not shouting.
Even when it's just a whisper. Even the lowest whisper can be heard over armies... when it's
telling the truth. `}</p>
    </blockquote>
    <p>{`It's time to `}<a parentName="p" {...{
        "href": "https://www.technologyreview.com/2022/04/22/1050394/artificial-intelligence-for-the-people/"
      }}>{`give value back to each voice`}</a>{`.`}</p>
    <h2 {...{
      "id": "roots",
      "style": {
        "position": "relative"
      }
    }}>{`Roots`}<a parentName="h2" {...{
        "href": "#roots",
        "aria-label": "roots permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`We must converse in a manner that both engenders a force of healing and reflects the cultural currency of relationality and reciprocity. - `}<a parentName="p" {...{
          "href": "https://spectrum.library.concordia.ca/id/eprint/986506/7/Indigenous_Protocol_and_AI_2020.pdf"
        }}>{`Indigenous Protocol`}</a></p>
    </blockquote>
    <p>{`It can be difficult for technologists to understand what constitutes real conversation and why
it is so critical in any human endeavour. However, we are privileged to have representatives
from the `}<Link to="https://harolddavis3.github.io/Two-Row-Wampum-Social-Layer-Platform" mdxType="Link">{`Two Row Wampum Working Group`}</Link>{` and the historical Longhouse Council of the Five Nations Confederacy participating with us, and below you can find a summary of some of the resources they have made available. We do not need to reinvent the wheel - we only need to rediscover our shared roots. In truth, there is only one human family.`}</p>
    <p>{`The `}<Link to="https://orionmagazine.org/article/the-rights-of-the-land/" mdxType="Link">{`transformative power of indigenous dialogue`}</Link>{` resides largely in the protocols for reaching
consensus between human beings which have been passed down and stress-tested over countless
generations, in constantly changing contexts. These protocols secure coherent and sustainable
notions of community, while allowing for the creation of new bottom up structures to respond
to the particular needs of any given time. The protocols we are interested in are `}<strong parentName="p">{`matrilineal`}</strong>{`:
they have been protected by our mothers for over two and a half thousand years in the undying
hope that they will continue to provide fertile ground for growing a lasting peace within and
between all people. Not having to start from scratch when creating sustainable and autonomous
structures is a huge advantage we shouldn’t take for granted.`}</p>
    <p>{`What is required now, more than ever, is the expression and lived realisation of beautiful
social resilience in diversity. That said, we also humbly acknowledge that we can never fully
express our gratitude to the women of the Longhouse in `}<em parentName="p">{`Kahnawake`}</em>{`, who have kept these peace
protocols alive for us to learn from today.`}</p>
    <p>{`The Five Nations Longhouse Confederacy and the Two Row Wampum Peace Treaty present a
fascinating means of interrogating the concept of nationhood. The Longhouse flag can be
flown `}<Link to="https://www.seashepherd.org.uk/news-and-commentary/news/sea-shepherd-receives-the-flag-of-the-five-nations-of-the-iroquois-confederacy.html" mdxType="Link">{`on all seas`}</Link>{`,
as it represents an indigenous American sovereign nation.`}</p>
    <p>{`In order to understand more deeply the revolutionary implications of this kind of nationhood,
combined with shared, ownerless, global ledgers, we must start with a new theory of value,
rooted in accurate anthropology.
Enter `}<Link to="https://www.researchgate.net/publication/27224530_Toward_an_Anthropological_Theory_of_Value_The_False_Coin_of_Our_Own_Dreams" mdxType="Link">{`David Graeber`}</Link>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`It would also help explain the extraordinary importance of indigenous people’s struggles in
the new movement: such people tend to be simultaneously the very least alienated and most
oppressed people on earth. Now that new communication technologies have made it possible to
include them in global revolutionary alliances, as well as local resistance and revolt, it is
well-nigh inevitable that they should play a profoundly inspirational role."`}</p>
    </blockquote>
    <h2 {...{
      "id": "trunk",
      "style": {
        "position": "relative"
      }
    }}>{`Trunk`}<a parentName="h2" {...{
        "href": "#trunk",
        "aria-label": "trunk permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`First, as my mother taught me, the Mohawk man — or any man — is supposed to stand when
facing women, or anyone else, when he speaks. He is not supposed to sit; otherwise people
cannot decode his body language. If this man sits, he could lie. What my mother also taught
me about Kaianerahse’rakó:wa, the Great Law of Peace and Understanding, is that I always have
to identify myself. I am Katsitenserio, which means “Pretty bird”. This is the name that my
mother gave me. I also identify my mother by her name: Kahawenontie, which means “She carries
the grapes." `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`When she was alive, she carried the sacred title of Female Chief into the Kaianerahse’rakó:wa
in the Longhouse. My mother taught me that every man, before getting up and talking, must
remember where he comes from. So I begin by thanking the first being of all Creation:
Ieronhiakaiehronon, the Sky Woman. I thank her, but I do not settle for simply thanking her,
I ask her to watch what I say, because when I speak, I stand before her. I ask her to listen
to my words. I also ask her to help me and guide me, so that my words are not lies, but are
the truth. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`I also thank the beating heart of our Mother Earth: our Grandmother Moon, whom we all know,
especially women, since the Moon visits them thirteen times a year and unites women with Mother
Earth and Grandmother Moon — all forming one. Grandmother Moon moves the blood of life, not
only in our bodies, but also in the body of our Mother Earth. Grandmother Moon is the beating
heart of Mother Earth, the same way that we have a heart that beats in our own bodies. The
function of our physical heart is exactly the same as the heart of Mother Earth. I thank our
Mother Earth, because she is mother of us all; she is the mother of all that exists. We all
come from the Earth, so we have to thank her. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`And as I did for Sky Woman, whom I ask to guide my words, I also ask Grandmother Moon and
Mother Earth to be with me and to watch my words. Thus, no bad word, or lie can be expressed.
Now, I thank all women, from the newborn baby girl to the eldest grandmother, since they are
all mothers of our nations. If you carry a little girl in your womb right now, she has perhaps
not yet arrived in this world and perhaps she has not given birth, but she is a woman, which
makes her the mother of our nation. She must therefore be treated with great respect. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Whether they come from the other side of the world, are white, black or yellow: all women
are our mothers — even the youngest girls are our mothers and should be treated as such. We
thank all life-givers, in all strains of life, as they are our purpose and direction in this
world. The true managers of our societies. We ask our ancestors of the Good Mind to help us
restore them to their rightful seats of power. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`I thank all our ancestors who came before us and who carry the Good Mind. This does not
exclude the fact that we all have ancestors who were stupid, who did bad things in this world,
but we do not condemn them. In evolution, we reject what is bad and we build upon only what is
good. So when we speak and we evolve, we identify what is bad and we know that we have nothing
to do with it. We therefore ask our ancestors who bear wisdom to help us return to Atonhetsera,
Spirit/ True Power, and the real power of women: not the power that you want as women, but the
real one. There are men who covet the power, but it is not the real power of man. Some men are
wrong. Some women also may go astray from the right path, even though they give life.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We therefore ask our ancestors to help us bring the real power of Atonhetsera, the
Tekenitehio hate, the Two Row Wampum, and the True Spirit of Asen nikatarake from the three
Universal Clans: Turtle, Wolf and Bear, which are those of the Kanienke’ha’ka, the People of
the Flint.`}</p>
    </blockquote>
    <p>{`— `}<Link to="https://a.co/9nnyt8e" mdxType="Link">{`Words of Peace in Native Land`}</Link>{`:
Mohawk culture, values and traditions by Katsitenserio (Stuart jr Myiow),
Wolf Clan Speaker/Representative & Guylaine Cliche.`}</p>
    <h2 {...{
      "id": "branches",
      "style": {
        "position": "relative"
      }
    }}>{`Branches`}<a parentName="h2" {...{
        "href": "#branches",
        "aria-label": "branches permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Of course, the best way to understand any protocol is to `}<em parentName="p">{`experience`}</em>{` it by talking with us.
However, we provide here a brief overview of some key words to begin painting the picture.`}</p>
    <h3 {...{
      "id": "chief",
      "style": {
        "position": "relative"
      }
    }}>{`Chief`}<a parentName="h3" {...{
        "href": "#chief",
        "aria-label": "chief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Is more a position of `}<em parentName="p">{`responsibility`}</em>{` rather than power, as most modern people imagine that
word. If someone wants to be chief, they are likely ill-suited for the role. The original
word actually translates more accurately to "the best of us", and this is a position which
can be occupied by men or women. However, it is always chosen by women title-holders and
requires 100% consensus. These same women have the power to "dehorn" the chief at any stage
if they feel that there is any possibility of corruption entering the office.`}</p>
    <h3 {...{
      "id": "hereditary-structure",
      "style": {
        "position": "relative"
      }
    }}>{`Hereditary structure`}<a parentName="h3" {...{
        "href": "#hereditary-structure",
        "aria-label": "hereditary structure permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Is always based on your clan, not necessarily your immediate blood or family. Parents cannot
pass down titles to their children, as in a monarchy. While Katsitenserio is a legal
title-holder today, as his mother has a clan, he also states the emergency ceremony embedded
into native protocols to make it legal and official under Five Nation's constitutional law:
`}<em parentName="p">{`Kaianerahse’rakó:wa`}</em>{`, The Great Law of Peace. This is a rare protocol, reduced to the smallest
possible surface area for attack, made for dire circumstances: that is, to prevent complete
genocide. Nations can be rebuilt even if only a single individual remains with knowledge of
the protocols. This would otherwise always be illegal, as Katsitenserio's mother was Wolf Clan
Mother, "the best of us" in the Longhouse. `}</p>
    <h3 {...{
      "id": "ancestral-lines",
      "style": {
        "position": "relative"
      }
    }}>{`Ancestral Lines`}<a parentName="h3" {...{
        "href": "#ancestral-lines",
        "aria-label": "ancestral lines permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Introductions are made, before any discussion is started, on the basis of your hereditary clan:
it is how we meet the deeper aspects of our humanity in one another. By consciously tracing
and `}<Link to="https://en.wikipedia.org/wiki/Songline" mdxType="Link">{`communicating the lines`}</Link>{` which lead to us, here and now, we can protect against nepotism
and ensure those who represent us are truly the best of any clan, which act as containers for
certain responsibilities and ways of knowing or being in our shared world. Your clan is passed
down through your mother.`}</p>
    <p>{`These imagined/imagining lines link not only speaker and listeners, bodies and lands, individuals and community, but dream and waking life too, as `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1pNwdVJT1Iz2gYQbhsTV4NgR6y9NAI1RYAQ2P4kexRBM/edit?usp=sharing"
      }}>{`illustrated beautifully in this research paper by Renata Morais`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Amerindian Indigenous societies consider dreams to be atemporal thresholds that mediate inner and external realities without any kind of ontological difference between the two `}{`[...]`}{` The past and the future collapse into the present of the dream, allowing the dreamer to time travel across collective images that are multitemporal and interdependent, echoing the Indigenous conception of dreams as doors to the spirit world.`}</p>
    </blockquote>
    <h3 {...{
      "id": "justice",
      "style": {
        "position": "relative"
      }
    }}>{`Justice`}<a parentName="h3" {...{
        "href": "#justice",
        "aria-label": "justice permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`To jail or imprison a human being in Longhouse oral documentation has always been illegal. It
follows that anything even closely resembling chattel-style slavery - including modern wage
slavery - is unjust. Indigenous cultures across the world speak of "bringing into the heart
fire" any person who has done wrong. This is consistent with other matrilineal justice
mechanisms in Africa, such as those mentioned by the polymath Cheikh Anta Diop. Diop describes
precolonial West African nations having intricate protocols for bringing local/regional rule
benders into the family for a determined period of time — after which it was then illegal to
disparage the person by mentioning the crime or that time of the person’s life. This last
nuance actually points to elaborate cultural protections against free labor across social
strata.`}</p>
    <h3 {...{
      "id": "guardianship",
      "style": {
        "position": "relative"
      }
    }}>{`Guardianship`}<a parentName="h3" {...{
        "href": "#guardianship",
        "aria-label": "guardianship permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Is best exemplified by the Māori word `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Kaitiaki"
      }}>{`kaitiaki`}</a>{`,
which was greatly misinterpreted in the Treaty of Waitangi. It does not mean ownership or the
ability to rule as the British claimed, but a kind of conscious stewardship, a `}<em parentName="p">{`being with`}</em>{`
the land and sea and sky. It is an expression of interdependence which still calls for
specific roles to be fulfilled by those most able to do so. Other indigenous people capture
this same idea in the notion of `}<a parentName="p" {...{
        "href": "https://vimeo.com/20278227"
      }}>{`The Children's Fire`}</a>{`.`}</p>
    <h3 {...{
      "id": "minding-the-peace",
      "style": {
        "position": "relative"
      }
    }}>{`Minding the peace`}<a parentName="h3" {...{
        "href": "#minding-the-peace",
        "aria-label": "minding the peace permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The League of Peace formed 2,500 years ago as a response to the social climate in Eastern
Seaboard of North America. Starting after Wampum shells began to be commodified as result of
the outbreak of war between the Five Nations (East → West: Mohawk, Oneida, Onadoga, Cayuga,
Seneca). War meant it simply wasn’t safe to collect these shells from the lakes. In the
tradition, war is the result of - and perpetuates - a `}<strong parentName="p">{`corrupted mind`}</strong>{` which is content to
have others work for it via control and commodification of first the wampum shells, then
everything else. This mind was successfully transformed by the matrilineal council that
protected long term peace, right up until total colonization.`}</p>
    <h3 {...{
      "id": "violent-commodification",
      "style": {
        "position": "relative"
      }
    }}>{`Violent commodification`}<a parentName="h3" {...{
        "href": "#violent-commodification",
        "aria-label": "violent commodification permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Oral testimony stands witness to the intricate relationship between violence and
commodification. This suggests that collective decision making processes for peaceful
diplomacy, protected by matrilineal heritage, ought to be embedded into any long-term
sustainable system. Without this protection, everything is at risk of commodification.
Violence creates artificial scarcity that bleeds into every aspect of our
socio-ecological-economic organization. Without matriineal protections, commodification is in
turn enhanced by the means with which we track the morphology of long-term value dynamics.
What is ultimately supposed to be a tool for tracking the collective health of our mother's
body (Earth) becomes a tool for her brutalization instead.`}</p>
    <h3 {...{
      "id": "balance",
      "style": {
        "position": "relative"
      }
    }}>{`Balance`}<a parentName="h3" {...{
        "href": "#balance",
        "aria-label": "balance permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Balance always has to come from where there is more" — Katsitenserio`}</p>
    </blockquote>
    <p>{`Which makes quantifying the value of goods less relevant than the mechanism by which we keep
record. Indigenous modes of tracking transactions are less about valuation and more about
ensuring balance is maintained within complex communities. If the means of valuation are
static, they will be gamed by those who can exercise greater coercive power; and organising
around regenerative abundance is replaced by organisations premised on scarcity. Without
dynamic consensus protocols for arriving at value, our entire regenerative heritage is made
invisible and then easily exploited, from the smallest levels to the largest levels of Sky
Woman's spiral design. This is identified in the oral documentation very clearly.   `}</p>
    <h2 {...{
      "id": "buds",
      "style": {
        "position": "relative"
      }
    }}>{`Buds`}<a parentName="h2" {...{
        "href": "#buds",
        "aria-label": "buds permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`Dialogue is a way of observing, collectively, how hidden values and intentions can control
our behavior, and how unnoticed cultural differences can clash without our realizing what is
occurring. It can therefore be seen as an arena in which collective learning takes place and
out of which a sense of increased harmony, fellowship and creativity can arise.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Dialogue can be considered as a free flow of meaning between people in communication, in the
sense of a stream that flows between banks.`}</p>
    </blockquote>
    <p>{`Establishing the shared patterns of a peaceful mind is a `}<strong parentName="p">{`critical`}</strong>{` aspect of Kernel. Though
we cannot easily quantify it, indigenous protocols for honest, clear, and open dialogue are
about `}<em parentName="p">{`restoring our social consciousness`}</em>{`, which is the only way to bring about reliable peace
between the diverse branches of the human family. This is not a new idea, and
is `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/#old-gifts-anew"
      }}>{`hinted at across the syllabus`}</a>{`. David Bohm
(a English theoretical physicist) and Jiddu Krishnamurti (an Indian sage) also understood the
importance of honest dialogue between diverse participants. The stakes are nothing less than
the reconstitution of a `}<strong parentName="p">{`common coherent consciousness`}</strong>{`, which is the only kind of mind
capable of using a shared, ownerless, global state and history for the benefit of all who
participate therein.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The spirit of dialogue is, in short, the ability to hold many points of view in suspension,
along with a primary interest in the creation of a common meaning.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`With this common coherent consciousness we have a new kind of intelligence capable of
thinking together. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`We must have dialogues, we must share our thoughts. We must be able to think together. If we
can’t think together and talk together, then we can do nothing together. Culture implies
shared meaning in which everybody participates.`}</p>
    </blockquote>
    <h3 {...{
      "id": "designing-conversations",
      "style": {
        "position": "relative"
      }
    }}>{`Designing Conversations`}<a parentName="h3" {...{
        "href": "#designing-conversations",
        "aria-label": "designing conversations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The modern thread that retrieves many of the notions above in a language and framework which many technologists may find more accessible is second-order cybernetics and it's natural implication: which ic nothing other than conversation; than design as a political process of rhetoric.`}</p>
    <List mdxType="List">
      <Link to="https://pangaro.com/published/Cybernetics_Design_Conversations_Action-Dubberly-&-Pangaro-2015.pdf" mdxType="Link">
        <p>{`Designers create possibilities for others to have conversations, to learn, and to act.`}</p>
      </Link>
    </List>
    <p>{`This simple idea reflects a recursively playful pattern you will find repeated throughout the syllabus, from `}<a parentName="p" {...{
        "href": "/learn/module-5/prosocial-value"
      }}>{`designing wholesome games`}</a>{`, to using `}<a parentName="p" {...{
        "href": "/build/tokenomics/adaptive-sharing"
      }}>{`adaptive, interacting incentive agents`}</a>{` whose purpose is to create games that incentivise others to act in prosocial ways, to how `}<a parentName="p" {...{
        "href": "/build/open-data"
      }}>{`open data might make of any time we share a more friendly and pleasing gift`}</a>{` to those we choose consciously to share it with. Importantly, though all of these examples point at strange loops that can lead us ever deeper into `}<a parentName="p" {...{
        "href": "/build/games"
      }}>{`playful conversations`}</a>{`, we must remember that it is incumbent upon us as second-order cybernetic designers to place `}<a parentName="p" {...{
        "href": "/learn/module-3/humility"
      }}>{`humility`}</a>{` and `}<a parentName="p" {...{
        "href": "/guiding/relating-kernel/#the-opening-empty"
      }}>{`response-ability`}</a>{` at the very heart of our shared practice so that we might `}<a parentName="p" {...{
        "href": "/learn/module-1/dreamers/#a-free-public"
      }}>{`remain ever vigilant`}</a>{` and aware:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The signage system is never completely finished, never completely specified, never completely imagined. It is forever open. Second-order design is born."`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"Whether designing interactive environments as computational extensions of human agency or new social discourses for governing social change, `}<strong parentName="p">{`the goal of second-order design is to facilitate the emergence of conditions in which others can design`}</strong>{`—and thus to increase the number of choices open to all."`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      